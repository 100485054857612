/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react'
import Moment from 'moment'
import produce from 'immer'
import { Icon } from '@ant-design/compatible'
import { Skeleton, Modal, message, Popover} from 'antd'
import * as S from './styles'
import { api } from '../../../providers/ApiProvider'
import CalendarStandardVersion from '../CalendarStandardVersion'
import CalendarTimelineVersion from '../CalendarTimelineVersion'
import CalendarEventForm from '../CalendarEventForm'
import * as c from '../../../utils/calendarEvents'
import getCalendarListWithStepEvents from './utility/getCalendarListWithStepEvents'
import getCalendarTimelineEvents from './utility/getCalendarTimelineEvents'
import getSelectedUserEntityCouple from './utility/getSelectedUserEntityCouple'
import { __ } from '../../../utils/translationUtils'
import {Button} from "components";

const { NON_WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes
const { CALENDAR, TIMELINE, AGGREGATED } = global.constants.calendarMode

export default (props) => {
  const {
    selectedUsers, selectedCompanies, calendarView, refetchCount, selectedDate, viewMode, attributes, selectedEventTypes, selectable, editable, calendarControlProps, eventTypeCodes, onShowMore, onNavigate, companiesTree, attachedUsersMap
  } = props

  const [expandedCalendar, setExapenCalendar] = useState(null)
  const [isSubmitting, setSubmit] = useState(null)
  const [loading, setLoading] = useState(null)
  const [calendarList, setCalendarList] = useState([])
  const [selectedSlot, selectSlot] = useState()
  const [activeEditEvent, setActiveEditEvent] = useState()
  const [stepEvents, setStepEvents] = useState([])
  const [entities, setEntities] = useState([])
  const [calendarAggregatedEvents, setCalendarAggregatedEvents] = useState([])
  const { startDate, endDate } = useMemo(() => c.getCalendarVisibleDateRange({ selectedDate, calendarView }), [selectedDate, calendarView])
  const visibleStart = useMemo(() => Moment(startDate), [startDate])
  const visibleEnd = useMemo(() => Moment(endDate), [endDate])
  const calendarListWithStepEvents = useMemo(() => getCalendarListWithStepEvents({ calendarList, stepEvents }), [calendarList, stepEvents])
  const selectedUserEntityCouple = useMemo(() => getSelectedUserEntityCouple(selectedUsers), [selectedUsers])
  const calendarTimelineEvents = useMemo(() => getCalendarTimelineEvents({ calendarListWithStepEvents, selectedDate, editable }), [calendarListWithStepEvents, selectedDate, editable])

  useEffect(() => {
    async function fetchCalendarList() {
      setLoading(true)

      const entities = await api.get('/entities?pageSize=200&status=enabled,reserved').then((data) => data.data)

      const calendarEventList = await c.getCalendarEventList({ items: selectedUserEntityCouple, startDate, endDate, selectedEventTypes })
        .then((data) => data.map((event) => ({
          ...event,
          user: selectedUsers.find((user) => user.id === event.userId),
          entity: entities.find((entity) => entity.id === event.entityId)
        })))
        .catch((e) => console.log(e)) || []

      setCalendarList(selectedUsers.map( (user) => {
        return {
          user,
          userEntities: user.belongsTo,
          events: calendarEventList.filter((e) => e.userId === user.id)
        }
      }))
      setEntities(entities)
      setLoading(null)
    }

    fetchCalendarList()
  }, [selectedEventTypes, selectedUsers, selectedUserEntityCouple, startDate, endDate, editable, refetchCount])

  useEffect(() => {
    async function fetchAggregatedEvents() {
      setLoading(true)
      const aggregatedCalendarData = await c.getCalendarAggregatedEvents({ startDate, endDate, items: selectedUserEntityCouple })
      if (aggregatedCalendarData) setCalendarAggregatedEvents(aggregatedCalendarData)
      setLoading(false)
    }

    if (viewMode === AGGREGATED) fetchAggregatedEvents()
  }, [startDate, endDate, selectedUserEntityCouple, refetchCount, viewMode])

  async function handleEventDelete({ userId, ...event }) {
    const deletedCalendarEvent = await c.deleteCalendarEvent(event)

    if (deletedCalendarEvent) {
      message.success(__('Event deleted'))
      const calendarIndex = calendarList?.findIndex((calendar) => calendar.user.id === userId)
      setCalendarList((calendars) => produce(calendars, (draftState) => {
        // eslint-disable-next-line no-param-reassign
        draftState[calendarIndex].events = draftState[calendarIndex].events.filter((e) => e.id !== deletedCalendarEvent)
      }))
      return
    }
    message.error(__('Something wrong'))
  }

  function handleActiveEditEvent(event) {
    setActiveEditEvent({
      ...event,
      user: selectedUsers.find((user) => user.id === event.userId)
    })
  }
  async function handleEditEvent(formValues) {
    const { entitiesId, eventType, nonWorkingTypeCode } = formValues

    const entityId = entitiesId?.[0]
    const { user, id } = activeEditEvent
    setSubmit(true)
    const editedEvent = await c.editCalendarEvent({ ...formValues, id })
    setSubmit(false)
    if (editedEvent) {
      message.success(`${eventType} ${__('Edited')}!`)
      const calendarBeingModifiedIndex = calendarList?.findIndex((calendar) => calendar.user.id === user.id)
      const entity = user.belongsTo.find((e) => e.id === entityId)

      setCalendarList((calendars) => produce(calendars, (draftState) => {
        // eslint-disable-next-line no-param-reassign
        draftState[calendarBeingModifiedIndex].events = draftState[calendarBeingModifiedIndex].events.filter((event) => event.id !== editedEvent.id)
        draftState[calendarBeingModifiedIndex].events.push({ ...editedEvent, nonWorkingTypeCode, eventType, entity, entityId, userId: user.id })
      }))
      setActiveEditEvent(null)
    }
  }

  function handleCloseModal() {
    selectSlot(null)
    setActiveEditEvent(null)
  }

  async function handleCreateEvent(formValues) {
    const { entityId, nonWorkingTypeCode, eventType, userId, user } = formValues

    setSubmit(true)
    const selectedUserId = userId || selectedSlot?.user?.id
    const selectedUser = user || selectedSlot?.user
    const createdEvent = await c.createCalendarEvent({ ...formValues, userId: selectedUserId, entityId })
    setSubmit(false)
    if (createdEvent) {
      const entity = entities.find((entity) => entity.id === selectedUser.entityId)

      const calendarBeingModifiedIndex = calendarList?.findIndex((calendar) => calendar.user.id === selectedUserId)

      const newEvent = { ...createdEvent, entity, entityId, userId: selectedUserId, nonWorkingTypeCode }
      if (newEvent.eventType === GODOO_EVENT) {
        selectSlot(null)
        setStepEvents((e) => [...e, newEvent])
        return
      }
      if (calendarBeingModifiedIndex === -1) return
      setCalendarList((calendars) => produce(calendars, (draftState) => {
        draftState[calendarBeingModifiedIndex].events.push(newEvent)
      }))
      selectSlot(null)
    }
  }

  return (
    <S.Dashboard>
      {
        (loading) && (
          <div style={{ display: 'flex' }}>
            <div style={{ position: 'relative', backgroundColor: 'rgb(243, 243, 243)', height: '811px', width: '500px', marginRight: '2rem', borderTopLeftRadius: '2rem' }}>
              <Skeleton avatar active paragraph={{ rows: 12 }} />
            </div>
            <div style={{ position: 'relative', backgroundColor: 'rgb(243, 243, 243)', height: '811px', width: '500px', marginRight: '2rem', borderTopLeftRadius: '2rem' }}>
              <Skeleton avatar active paragraph={{ rows: 12 }} />
            </div>
            <div style={{ position: 'relative', backgroundColor: 'rgb(243, 243, 243)', height: '811px', width: '500px', marginRight: '2rem', borderTopLeftRadius: '2rem' }}>
              <Skeleton avatar active paragraph={{ rows: 12 }} />
            </div>
            <div style={{ position: 'relative', backgroundColor: 'rgb(243, 243, 243)', height: '811px', width: '500px', marginRight: '2rem', borderTopLeftRadius: '2rem' }}>
              <Skeleton avatar active paragraph={{ rows: 12 }} />
            </div>
          </div>
        )}
      {(!loading) && expandedCalendar && (
        <Modal
          title={`${expandedCalendar.item.username} ${__('Calendar')}`}
          visible
          width="90vw"
          footer={null}
          onOk={() => setExapenCalendar(null)}
          onCancel={() => setExapenCalendar(null)}
          afterClose={document.body.removeAttribute('style')} //for bug described here: https://github.com/ant-design/ant-design/issues/21539
        >
          <CalendarStandardVersion
            date={selectedDate}
            hasGravatar
            attributes={attributes}
            toolbar={false}
            view={calendarView}
            userEntities={expandedCalendar.item.belongsTo}
            user={expandedCalendar.item}
            events={expandedCalendar.events}
            calendarStyle={{ height: '50vh', maxHeight: 'auto', minHeight: '630px' }}
            entities={entities}
          />
        </Modal>
      )}

      {(!loading) && selectedSlot && (
        <Modal
          title={`${__('Event')}: ${selectedSlot?.user ? `${selectedSlot.user.firstName} ${selectedSlot.user.lastName}` : ''}`}
          visible
          footer={
            <div>
              <Button
                form="calendar-event-form-id"
                title={__('Create event')}
                type='filled'
              />
            </div>
          }
          onOk={() => selectSlot(null)}
          onCancel={() => selectSlot(null)}
        >
          <CalendarEventForm
            initialValues={{
              dateRange: [selectedSlot.startDate, selectedSlot.endDate],
              eventType: calendarControlProps ? GODOO_EVENT : NON_WORKING_EVENT
            }}
            selectedCompanies={selectedCompanies}
            calendarControlProps={calendarControlProps}
            user={selectedSlot.user}
            onSubmit={handleCreateEvent}
            isSubmitting={isSubmitting}
            eventTypeCodes={eventTypeCodes}
            closeModal={handleCloseModal}
            companiesTree={companiesTree}
            attachedUsersMap={attachedUsersMap}
          />
        </Modal>
      )}
      {(!loading) && activeEditEvent && (
        <Modal
          title={`${__('Editing')}: ${activeEditEvent.title || ''}`}
          visible
          footer={
            <div>
              <Button
                form="calendar-event-form-id"
                title={__('Edit event')}
                type='filled'
              />
            </div>
          }
          onOk={() => setActiveEditEvent(null)}
          onCancel={() => setActiveEditEvent(null)}
        >
          <CalendarEventForm
            initialValues={{
              ...activeEditEvent,
              dateRange: [Moment(activeEditEvent.start), Moment(activeEditEvent.end)]
            }}
            mode="edit"
            user={activeEditEvent.user}
            onSubmit={handleEditEvent}
            eventTypeCodes={eventTypeCodes}
            isSubmitting={isSubmitting}
          />
        </Modal>
      )}
      {
        (!loading) && viewMode === TIMELINE && (
          <CalendarTimelineVersion
            stackItems
            view={calendarView}
            handleExpandCalendar={setExapenCalendar}
            visibleStart={visibleStart}
            visibleEnd={visibleEnd}
            items={calendarTimelineEvents}
            groups={selectedUsers}
            attributes={attributes}
            selectable={selectable && !stepEvents?.length}
            editable={editable}
            onSelectSlot={selectSlot}
            onDelete={handleEventDelete}
            onEdit={handleActiveEditEvent}
          />
        )
      }

      {
        (!loading) && viewMode === AGGREGATED && (
          <CalendarStandardVersion
            handleExpandCalendar={setExapenCalendar}
            date={selectedDate}
            hasGravatar
            attributes={attributes}
            toolbar={false}
            view={calendarView}
            stepEvents={stepEvents}
            events={calendarAggregatedEvents}
            viewMode={viewMode}
            selectable={selectable && !stepEvents?.length}
            editable={editable}
            onShowMore={onShowMore}
            onNavigate={onNavigate}
            onSelectSlot={selectSlot}
            entities={entities}
          />
        )
      }

      {
        (!loading) && viewMode === CALENDAR && (
          <S.CalendarsWrapper>
            {calendarListWithStepEvents.map(({ user, userEntities, events }) => (
              <S.CalendarWrapper key={user.id}>
                <CalendarStandardVersion
                  onDelete={handleEventDelete}
                  onEdit={handleActiveEditEvent}
                  handleExpandCalendar={setExapenCalendar}
                  date={selectedDate}
                  hasGravatar
                  attributes={attributes}
                  toolbar={false}
                  view={calendarView}
                  userEntities={userEntities}
                  user={user}
                  events={events}
                  selectable={selectable && !stepEvents?.length}
                  editable={editable}
                  onShowMore={onShowMore}
                  onNavigate={onNavigate}
                  onSelectSlot={selectSlot}
                  entities={entities}
                />
              </S.CalendarWrapper>
            ))}
          </S.CalendarsWrapper>
        )
      }
      {(!loading) && stepEvents?.length > 0 && (
        <S.HandleDeleteStepEvents>
          <Popover content={() => <div>{__('Current events must be removed before adding new ones')}</div>} title={null}>
            <button type="button" className="btn btn-warning" onClick={() => setStepEvents([])}>
              <Icon type="delete" /> {__('Remove event')}
            </button>
          </Popover>
        </S.HandleDeleteStepEvents>
      )}
    </S.Dashboard>
  )
}
