import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {find, isEmpty} from 'lodash'
import {Form, Space, Tooltip as AntdTooltip} from "antd";

import {
  checkUserRights,
  convertArrayToObject,
  fetchEnumById,
  fetchRemoteObjectConfiguration,
  fetchRemoteObjectEntries,
} from '../../utils/appHelper'

import CreateInstance from "../../components/RemoteObjectInstances/CreateInstance";
import SearchParameterForm from "../../components/RemoteObjectInstances/Filter/SearchParameterForm";
import {SortForm} from '../../components/UI/SmartTable/Header';
import {ExportModal, RemoteObjectTable} from "../../components/RemoteObjectInstances";
import {ActionOptions, BatModal, CheckboxList, Progress, Tooltip} from "../../components/UI";
import {SmartFooter} from "../../components/SmartFooter";
import {ControlOutlined, DownloadOutlined, RedoOutlined, SortAscendingOutlined, TableOutlined} from "@ant-design/icons";

import useProgress from "../../hooks/useProgress";
import {getDisplayAttributes, getDisplayAttributesItems} from "../../utils/displayAttributeHelper";
import {getColumnSort} from "../../utils/tableUtils";
import {ERROR} from "components/dist/Utils/LoggerUtils";
import {getSearchAttributes, getSearchFields} from "./utils";
import * as S from "../../providers/StyleProvider/styles";
import {IconButton, ScrollableListView, SettingsContainer} from "../../providers/StyleProvider/styles";
import {Button} from "components"
import * as config from "../../constants/globalConfiguration";
import {hashHistory} from "../../providers/HistoryProvider";
import {parse} from "query-string";
import {useSelector} from "react-redux";
import {availableExportOptions} from '../../components/RemoteObjectInstances/ExportModal/contants';
import {__} from '../../utils/translationUtils'
import {UseAttributesQuery} from "../../providers/QueryClientProvider/queries";
import {setLastRemoteObjSearchActionCreator} from "../../providers/ReduxProvider/actions/userActions";
import {computeFiltersCount, SearchAttributeInverseMapping} from "../../utils/attributeUtils";
import {lastSearchSelector} from "../../providers/ReduxProvider/reducers/uiReducer";
import useCache from "../../hooks/useCache";

const {rightMappings} = global.constants

const RemoteObjectInstances = (props) => {
  const {data: attributeList} = UseAttributesQuery()
  const {params} = props.match

  const progress = useProgress(21)
  const [isLoading, setIsLoading] = useState(true);
  const guiUser = useSelector((state) => state?.user.data)

  const [remoteObjectConfiguration, setRemoteObjectConfiguration] = useState(null);
  const [remoteObjectData, setRemoteObjectData] = useState(null);

  const [currentSearch, setCurrentSearch] = useState({});
  const [currentSort, setCurrentSort] = useState([]);

  const displayAttributes = useMemo(() => getDisplayAttributes(remoteObjectConfiguration?.displayAttributes, attributeList, __), [attributeList, remoteObjectConfiguration])
  const displayableAttributesFilter = useMemo(() => getDisplayAttributesItems(remoteObjectConfiguration?.displayAttributes, attributeList, __), [attributeList, remoteObjectConfiguration])
  const [visibleColumns, setVisibleColumns] = useState(displayableAttributesFilter.map((a) => (a.value)))

  const [exportOption, setExportOption] = useState(undefined)
  const [currentSortCol, setCurrentSortCol] = useState(null);
  const [missingRequiredSearchAttributes, setMissingRequiredSearchAttributes] = useState(null);
  const [currentPagination, setCurrentPagination] = useState({
    pageNumber: 1,
    pageSize: 25,
    totalCount: 0
  });
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [sortModalOpen, setSortModalOpen] = useState(false)
  const [enumValues, setEnumValues] = useState([]);

  const [searchForm] = Form.useForm()

  const editedSearchAttributes = useMemo(() => computeFiltersCount(currentSearch, getSearchFields(remoteObjectConfiguration?.searchAttributes)), [currentSearch, remoteObjectConfiguration])
  const editedSortAttributes = useMemo(() => computeFiltersCount(currentSort, remoteObjectConfiguration?.sortAttributes), [currentSort])
  const editedSelectedAttributes = useMemo(() => displayableAttributesFilter.map((a) => (a.value)).length - visibleColumns.length, [visibleColumns])

  const {loading: cacheLoading, data: lastCached, saveCache} = useCache(params.id, lastSearchSelector)

  useEffect(() => {
    fetchRemoteObjectConfiguration(params.id)
      .then(async (response) => {
        let remoteObjConfiguration = response.data
        setRemoteObjectConfiguration(remoteObjConfiguration)
        const requiredSearchAttributes = getMissingRequiredSearchAttributes(getSearchFields(remoteObjConfiguration.searchAttributes), remoteObjConfiguration)
        setMissingRequiredSearchAttributes(requiredSearchAttributes)
        setSearchModalOpen(!!requiredSearchAttributes)
      })
      .catch(error => {
        if (error.request && error.request.status === 404) {
          const queryString = parse(props.location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })
  }, [params.id])

  useEffect(() => {
    const fetchData = async () => {
      const enumsToGetValues = []
      if (!isEmpty(attributeList) && !isEmpty(remoteObjectConfiguration) && !cacheLoading) {

        let searchAttributes = remoteObjectConfiguration.searchAttributes
        let sortAttributes = remoteObjectConfiguration.sortAttributes
        let displayAttributes = remoteObjectConfiguration.displayAttributes
        let lastPagination

        if (lastCached?.payload) {
          try {
            const payloadObj = JSON.parse(lastCached.payload)
            const saMap = convertArrayToObject(payloadObj?.searchAttributes, 'attributeId')

            searchAttributes = searchAttributes.map((sa) => {
              const savedSA = saMap[sa.attributeId]
              return savedSA ? SearchAttributeInverseMapping(savedSA) : sa
            })

            sortAttributes = remoteObjectConfiguration.sortAttributes.map((sortAttr) => {
              const sa = payloadObj?.sortAttributes?.find(({attributeId}) => sortAttr?.attributeId === attributeId)
              return sa ? sortAttr : {...sortAttr, enabled: false}
            })

            displayAttributes = payloadObj.displayAttributes

            lastPagination = {
              pageSize: payloadObj.pageSize,
              pageNumber: payloadObj.pageNumber,
            }
          } catch (e) {
            ERROR(e)
          }
        }

        searchAttributes.forEach((attribute) => {
          const searchAttribute_attribute = find(attributeList, (c) => c.id === attribute.attributeId)
          if (attribute.mechanism === 'ENUMERATION' && searchAttribute_attribute.enumerationId) {
            const enumId = searchAttribute_attribute.enumerationId
            enumsToGetValues.push(enumId)
          }
        })

        if (enumsToGetValues.length > 0) {
          enumsToGetValues.forEach((enumId) => {
            fetchEnumById(enumId).then((enumerator) => {
              setEnumValues((prevValue) => [...prevValue, enumerator])
            })
          })
        }

        const search = getSearchFields(searchAttributes)
        const columns = getDisplayAttributesItems(displayAttributes, attributeList, __).map((a) => (a.value))

        //setInitialValues(search)
        setCurrentSearch(search)
        setCurrentSort(sortAttributes)
        setVisibleColumns(columns)

        submitSearch({
          search,
          sort: sortAttributes,
          columns: columns,
          pagination: {
            ...currentPagination,
            pageSize: lastPagination?.pageSize || remoteObjectConfiguration?.pageSize || currentPagination.pageSize,
            pageNumber: lastPagination?.pageNumber || remoteObjectConfiguration?.pageNumber || currentPagination.pageNumber
          }
        })
      }
    }
    fetchData()
  }, [remoteObjectConfiguration, attributeList, cacheLoading])


  const submitSearch = ({search, sort, columns, pagination, updateSort = true}) => {
    if (!search || !sort || !pagination || !columns?.length) {
      ERROR("One of the following is null. Search: ", search, " sort: ", sort, " pagination: ", pagination)
      return
    }

    if (missingRequiredSearchAttributes > 0) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const searchAttributes = getSearchAttributes(search, remoteObjectConfiguration, attributeList)
    const payload = {
      id: remoteObjectConfiguration.id,
      name: remoteObjectConfiguration.name,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      onlySelfOrders: remoteObjectConfiguration.onlySelfOrders,
      displayAttributes: remoteObjectConfiguration.displayAttributes.filter(({attributeId}) => columns.includes(attributeId)),
      sortAttributes: sort.filter(({enabled}) => enabled == null || enabled),
      searchAttributes,
      settings: remoteObjectConfiguration?.settings,
      objectTypeId: remoteObjectConfiguration.objectTypeId,
      systemId: remoteObjectConfiguration.systemId,
    }
    if (!missingRequiredSearchAttributes) {
      fetchRemoteObjectEntries(payload)
        .then((response) => {
          saveCache(setLastRemoteObjSearchActionCreator({
            cacheId: response?.headers?.["x-saved-request-id"],
            id: remoteObjectConfiguration.id
          }))

          setRemoteObjectData(response.data)
          setCurrentSearch(search)
          setCurrentPagination({
            ...pagination,
            totalCount: parseInt(response.headers['x-total-count']),
            pageSize: parseInt(response.headers['x-page-size']),
            pageNumber: parseInt(response.headers['x-page'])
          })
          if (updateSort) {
            setCurrentSort(sort)
          }
          setIsLoading(false)
        })
    } else
      setIsLoading(false)
  }

  const getMissingRequiredSearchAttributes = useCallback((search, remoteObjectConfig) => {
    const requiredAttributes = remoteObjectConfig?.searchAttributes.filter((f) => f.required)
    if (!requiredAttributes) return 0
    return requiredAttributes.reduce((prev, attr) => {
      if (!search[attr.attributeId]) {
        return prev + 1
      }
      return prev
    }, 0)
  }, [])

  const handleSubmitSearch = () => {
    submitSearch({
      search: currentSearch,
      sort: currentSort,
      columns: visibleColumns,
      pagination: currentPagination
    })
    setSortModalOpen(false)
  }

  const handlePaginationChange = ({pagination}) => {

    submitSearch(
      {
        search: currentSearch,
        sort: currentSort,
        columns: visibleColumns,
        pagination: {...currentPagination, ...pagination},
      })
  }

  const onSortColumnChange = (sortName, sortOrder) => {
    const sort = getColumnSort(sortName, sortOrder, currentSortCol)

    submitSearch(
      {
        search: currentSearch,
        sort,
        columns: visibleColumns,
        pagination: {...currentPagination},
        updateSort: false
      })
    setCurrentSortCol(sort)
  }

  return (
    <ScrollableListView id="main-content">
      {!!isLoading && <S.OverlayFullSpaceSpin/>}
      <h1 className="sticky">
        <div className="container">
          {remoteObjectConfiguration ? __(remoteObjectConfiguration.name, "capitalise") : __('loading')}
        </div>
      </h1>
      {
        !!remoteObjectConfiguration && (
          <SettingsContainer>
            <Space className="left" style={{marginLeft: "15px"}}>
              <BatModal
                buttonBadgeCount={editedSearchAttributes}
                disabled={!remoteObjectConfiguration?.searchAttributes?.length}
                buttonProps={{icon: <ControlOutlined/>}}
                title={__('Filters')}
                width={700}
                forceOpen={searchModalOpen}
                setForceOpen={setSearchModalOpen}
                onSubmitCallback={() => searchForm.submit()}
                onCancelCallback={() => {
                  searchForm.setFieldsValue(currentSearch)
                  setMissingRequiredSearchAttributes(getMissingRequiredSearchAttributes(searchForm.getFieldsValue(), remoteObjectConfiguration))
                }}
                footer={
                  <SmartFooter
                    onSubmit={() => {
                      searchForm.submit()
                    }}
                    onReset={() => searchForm.setFieldsValue(getSearchFields(remoteObjectConfiguration.searchAttributes))}
                  />
                }
              >
                <SearchParameterForm
                  fields={remoteObjectConfiguration.searchAttributes}
                  attributeList={attributeList}
                  form={searchForm}
                  enumerations={enumValues}
                  initialValues={currentSearch}
                  onValuesChange={() => setMissingRequiredSearchAttributes(getMissingRequiredSearchAttributes(searchForm.getFieldsValue(), remoteObjectConfiguration))}
                  onFinish={(values) => {
                    submitSearch({
                      search: values,
                      sort: currentSort,
                      columns: visibleColumns,
                      pagination: currentPagination
                    })
                    setSearchModalOpen(false)
                  }}
                />
              </BatModal>
              <BatModal
                buttonBadgeCount={editedSortAttributes}
                showBadgeCount={false}
                forceOpen={sortModalOpen}
                setForceOpen={setSortModalOpen}
                title={__('Sorting')}
                size='small'
                disabled={!remoteObjectConfiguration.sortAttributes?.length}
                buttonProps={{icon: <SortAscendingOutlined/>}}
                onSubmitCallback={() => handleSubmitSearch()}
                footer={
                  <div className='right'>
                    <Button
                      type="filled"
                      onClick={() => handleSubmitSearch()}
                    >
                      {__("Apply Sorting")}
                    </Button>
                  </div>
                }
              >
                <SortForm
                  value={currentSort}
                  onChange={setCurrentSort}
                  attributesList={attributeList}
                />
              </BatModal>
              <Tooltip
                buttonBadgeCount={editedSelectedAttributes}
                showBadgeCount={false}
                buttonProps={{
                  icon: <TableOutlined/>,
                  title: __('Columns')
                }}
              >
                <CheckboxList
                  items={displayableAttributesFilter}
                  value={visibleColumns}
                  onChange={(value) => {
                    setVisibleColumns(value)
                    submitSearch({
                      search: currentSearch,
                      sort: currentSort,
                      columns: value,
                      pagination: currentPagination
                    })
                  }}
                />
              </Tooltip>
              {
                (!!editedSearchAttributes || !!editedSortAttributes || !!editedSelectedAttributes) && (
                  <Button
                    type="text"
                    title={__('clear-filters', "capitalize_sentence")}
                    onClick={() => {
                      setVisibleColumns(displayableAttributesFilter.map((a) => (a.value)))
                      const search = getSearchFields(remoteObjectConfiguration.searchAttributes)
                      searchForm.setFieldsValue(search)

                      submitSearch({
                        search,
                        sort: remoteObjectConfiguration.sortAttributes,
                        columns: visibleColumns,
                        pagination: {
                          ...currentPagination,
                          pageSize: remoteObjectConfiguration?.pageSize,
                          pageNumber: remoteObjectConfiguration?.pageNumber,
                        }
                      })
                    }}
                  />
                )
              }
            </Space>
            <Space className="right" style={{marginRight: "15px"}}>
              {
                !!remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_CREATE_REMOTEOBJECT_ITEM) &&
                (
                  <CreateInstance
                    configuration={remoteObjectConfiguration}
                    displayDataIds={Object.keys(remoteObjectData?.[0]?.displayData || {})}
                    onSubmit={handleSubmitSearch}
                  />
                )
              }
              {
                !!remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_SEE_ALL_ORDERS) &&
                (
                  <div style={{float: "right", display: "inline-flex"}}>
                    {
                      exportOption && (
                        <ExportModal
                          objectName={remoteObjectConfiguration?.name}
                          open={exportOption}
                          onClose={() => setExportOption(undefined)}
                          payloadData={{
                            remoteObjectConfiguration,
                            currentPagination,
                            currentSearch,
                            currentSort,
                            visibleColumnsData: remoteObjectConfiguration.displayAttributes.filter(({attributeId}) => visibleColumns.includes(attributeId))
                          }}
                          commonProps={{attributeList}}
                        />
                      )}
                    <Space>
                      <AntdTooltip title={__('refresh data')}>
                        <IconButton shape="circle" onClick={handleSubmitSearch} icon={<RedoOutlined/>}/>
                      </AntdTooltip>
                      <ActionOptions
                        disabled={progress?.state?.active}
                        options={[
                          {
                            value: 'OnlyVisible',
                            label: __('Only visible results'),
                            onClick: () => setExportOption(availableExportOptions.partial),
                          },
                          {
                            value: 'All',
                            label: __('All results'),
                            onClick: () => setExportOption(availableExportOptions.total)
                          },
                          {
                            value: 'Exports',
                            label: __('last_exports'),
                            onClick: () => setExportOption(availableExportOptions.onlyExports)
                          },
                        ]}
                      >
                        <AntdTooltip title={__('export')}>
                          <div style={{position: 'relative'}}>
                            <IconButton shape="circle" icon={<DownloadOutlined/>}/>
                            <Progress {...progress?.state} />
                          </div>
                        </AntdTooltip>
                      </ActionOptions>
                    </Space>
                  </div>
                )
              }
            </Space>
          </SettingsContainer>
        )
      }
      {
        remoteObjectData && (
          <div className="container-fluid report-view-page">
            <div className={"not-snapshots"}>
              <RemoteObjectTable
                {...props}
                remoteObjectRecordsSpec={{displayData: displayAttributes}}
                data={remoteObjectData}
                viewConfiguration={remoteObjectConfiguration}
                attributesConfiguration={attributeList}
                remoteObjectSpec={remoteObjectConfiguration}
                pagination={currentPagination}
                isLoading={isLoading}
                onSortChange={onSortColumnChange}
                selectedColumns={visibleColumns}
                onPageChange={(pageNumber) => handlePaginationChange({pagination: {pageNumber}})}
                setPageSize={(pageSize) => handlePaginationChange({pagination: {pageSize}})}
                refreshData={handleSubmitSearch}
              />
            </div>
          </div>
        )}
      {
        !remoteObjectData && !isLoading &&
        <div style={{textAlign: "center", color: '#76777A', fontSize: '1.25em'}}><i>Missing required filters.</i>
        </div>
      }
    </ScrollableListView>
  )
}


export default RemoteObjectInstances
