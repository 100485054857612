import _ from 'lodash'

export function extractConfiguration (config, attributeId, idKey) {
  return _.find(config, (c) => c[idKey || 'id'] === attributeId)
}

export function extractDisplayConfiguration (config, attributeId) {
  return extractConfiguration(config, attributeId, 'attributeId')
}

export function extractDetailedDisplayableColumn (props) {
  const { field, selectedColumns, detailedFields } = props || {}
  const detailedField = extractConfiguration(detailedFields, field?.attributeId)
  if ((selectedColumns?.indexOf(detailedField?.propertyLabel) < 0) || !detailedField?.displayable) return null
  return detailedField
}

export function computeFiltersCount (formValues, initialValues) {
  if (!formValues || Object.keys(formValues).length === 0) return 0
  return Object.keys(formValues).reduce((totFilled, key) => {
    if (_.isEqual(formValues[key], initialValues[key])) return totFilled
    return totFilled + 1
  }, 0)
}

/**
 * Map a search attribute with values already   formatted for backend usage
 * @param searchAttribute
 * @returns searchAttribute
 */
export const SearchAttributeInverseMapping = (searchAttribute) => {
  return {
    ...searchAttribute,
      ...(searchAttribute.mechanism === "SPECIFIC_VALUE" && !searchAttribute.exactMatch ? { value: searchAttribute.value.replace(/^\*|\*$/g, '') } : {})
  }
}
